<template>
    <div class="page">
        <div class="newbox">
            <van-button type="primary" @click="scan_enter">扫描验票</van-button>
            <van-button type="primary" @click="toNav(1)">手动验票</van-button>
            <van-button type="primary" @click="toNav(2)">获取电子票</van-button>
        </div>


    </div>
</template>
<script>
    import {
        Field, Button
    } from "vant";

    import {activity_detail} from '@/request/api';
    import wx from "@/request/utils/wechat";//微信JSSDK

    export default {

        components: {
            [Field.name]: Field,
            [Button.name]: Button
        },
        data() {
            return {
                redirect_uri: '',
                activity:false,
                activity_id:0
            };
        },
        created() {
            this.scan_enter();
            this.redirect_uri = this.$route.query.redirect_uri; //获取从某个项目中跳转过来的域名或者Ip
        },
        mounted() {
            this.activity_id = this.$route.query.id;
            this.initData();
        },
        methods: {
            initData(){
                //1.获取活动详情
                activity_detail({activity_id: this.activity_id}).then(res => {
                    if (res.code == 1) {
                        this.activity = res.data;
                    } else {
                        this.$dialog({message: res.msg});
                    }
                });
            },
            scan_enter:function () {
              //调用扫码
               // this.$router.push({path: "/user/manage/operate/scanInfo",query:{code:'645607481571'}});
                wx.scanQRCode().then(res=>{
                    this.$router.push({path: "/user/manage/operate/scanInfo",query:{code:res,activity_id:this.activity_id}});
                });
            },
            toNav(type) {
                if(!this.activity){
                    this.$dialog({message: "活动数据不存在"});
                }
                let path = '';
                if (type == 1) {
                    path = '/user/manage/operate/checkticket';
                    this.$router.push({path: path,query:{id:this.activity.id,title:this.activity.activity_name}})
                } else {
                    path = '/user/manage/operate/getticket';
                    this.$router.push({path: path,query:{qr:this.activity.ticket_QRcode,title:this.activity.activity_name}})
                }

            }
        }
    };
</script>
<style lang="less">

    .newbox {
        height: 100vh;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        overflow: hidden;
        align-items: center;
        button {
            width: 150px;
            &::before {
                content: "";
                display: block;
                background: inherit;
                -webkit-filter: blur(5px);
                filter: blur(5px);
                position: absolute;
                width: 100%;
                height: 100%;
                top: 5px;
                left: 5px;
                z-index: -1;
                opacity: 0.4;
                -webkit-transform-origin: 0 0;
                -ms-transform-origin: 0 0;
                transform-origin: 0 0;
                border-radius: inherit;
                -webkit-transform: scale(1, 1);
                -ms-transform: scale(1, 1);
                transform: scale(1, 1);
            }
            &:nth-child(1) {
                background-color: #39b54a;
                border-color: #39b54a;
            }
            &:nth-child(2) {
                margin: 20vh 0;
                background-color: #0081ff;
                border-color: #0081ff;
            }
            &:nth-child(3) {
                background-color: #e54d42;
                border-color: #e54d42;
            }
        }
    }

</style>